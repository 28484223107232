.lm-pages {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.lm-page {
    height: 100%;
    box-sizing: border-box;
    padding: 0 20px 0 20px;
    max-width: 100vw;
    flex: 1;
}

@media (max-width: 800px) {
    .lm-pages {
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }

    .lm-page {
        padding-top: calc(44px + var(--lm-safe-area-top));
        padding-bottom: calc(44px + var(--lm-safe-area-bottom));
        height: 100%;
        min-width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        will-change: scroll-position;
        -webkit-overflow-scrolling: touch;
    }
}

.lm-slide-left-in {
    animation: lm-slide-left-in 0.4s forwards;
}

.lm-slide-left-out {
    animation: lm-slide-left-out 0.4s forwards;
}

.lm-slide-left-in::-webkit-scrollbar {
    display: none;
}

.lm-slide-left-out::-webkit-scrollbar {
    display: none;
}

.lm-loading::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    z-index: 9999;
    animation: lm-loading 2s linear infinite;
    background: #e0e0e0 repeating-linear-gradient(to right, var(--lm-main-color-deep, #66b244), var(--lm-main-color-deep, #66b244) 50%, #e0e0e0 50%, #e0e0e0);
    background-size: 200% 100%;
}

@media (min-width: 800px) {
    .lm-slide-left-in {
        position: relative;
    }

    .lm-slide-left-out {
        position: relative;
    }
}

/** Keyframes Left to Right **/
@keyframes lm-slide-left-in {
    0% { left: -100%; }
    100% { left: 0; }
}

@-webkit-keyframes lm-slide-left-in {
    0% { left: -100%; }
    100% { left: 0; }
}

@keyframes lm-slide-left-out {
    0% { left: 0; }
    100% { left: -100%; }
}

@-webkit-keyframes lm-slide-left-out {
    0% { left: 0; }
    100% { left: -100%; }
}

@keyframes lm-loading {
    from {
        background-position: 100% 0;
    }
    to {
        background-position: 0 0;
    }
}
